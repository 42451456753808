﻿@import '../../../../../Common/src/scss/components/react/ColorSelector.scss';

.ColorSelector {

    .btn-group button {
        height: 36px;
        background: $gray-ghost;
    }

    button.selected {
        img {
            border: 2px solid $white;
        }

        border: 2px solid $black;
    }

    @include media-breakpoint-down(sm) {
        .btn-group button {
            height: 30px;
        }

        .btn-group button:not(:last-child) {
            margin-right: 12.5px;
        }
    }
}
