﻿@import '../../../../../Common/src/scss/components/react/ProductDescriptionTab.scss';

.ProductDescriptionTab {

    h3 {
        margin-bottom: 22px;
    }

    padding {
        margin-bottom: 36px;
    }

    img {
        height: 420px;
        width: 496px;
    }

    @include media-breakpoint-down(sm) {
        .background {
            background-image: none !important;
        }
    }
}
