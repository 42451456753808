﻿@import '../../../../../Common/src/scss/components/react/ProductRating.scss';

.ProductRating {
    .far,
    .fa {
        &.fa-star {
            color: var(--primary);
        }
    }
}
