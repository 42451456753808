﻿.navigation__section {
    background-color: $black;
    padding-top: 12px;
    padding-left: 30px;
    margin-bottom: 10px;

    .nav-link {
        color: $white;
        font-weight: 400;
        font-size: 16px;
        letter-spacing: 0.25px;
        line-height: 36px;
        text-transform: none !important;
    }

    i {
        font-size: 18px;
        padding-right: 20px;
    }

    .navigation__item-active {
        float: right;
        font-size: 28px;
    }

    hr {
        border: 2px solid $gray-primary;
    }
}
