﻿a.bold {
    font-weight: 900;
}

.a-light,
.a-dark {
    padding: 0.5rem 0.5rem;
    font-family: var(--tertiary-font);
    @include font-size(16);
    letter-spacing: 0.25px;
    border: 2px solid transparent;
}

.a-light:hover {
    border-bottom: 2px solid $black;
    text-decoration-line: none;
}

.a-dark:hover {
    border-bottom: 2px solid var(--primary);
    text-decoration-line: none;
    color: var(--primary);
}

.a-dark:focus {
    border: 2px solid $white;
    color: $white;
}

.a-light:focus {
    border: 2px solid $black;
}

.a-light:active,
.a-dark:active {
    border: 2px solid transparent;
}

.a-dark {
    color: var(--primary);
}
