﻿.subheader {
    @include font-size(23px);
    letter-spacing: 0.1em;
    line-height: 0.7em;
    font-weight: 400;
}

.display {
    letter-spacing: -0.025em;
}

.display-1 {
    line-height: 0.85em;
}

.display-2 {
    line-height: 1em;
}

.display-3 {
    line-height: 1.05em;
}

p {
    @include font-size(19px);
}

.paragraph-2 {
    @include font-size(16px);
}

.paragraph-3 {
    @include font-size(14px);
}

blockquote {
    @include font-size(19px);
    font-family: var(--tertiary-font);
    letter-spacing: 0.25px;
    line-height: 1.68em;
    text-align: center;
}

// MOBILE
@include media-breakpoint-down('md') {
    .subheader {
        @include font-size(19px);
    }
}
