﻿label,
input,
div.input-checkbox,
div.input-radio {
    font-family: var(--tertiary-font);
    @include font-size(16);
    letter-spacing: 0.25px;
    line-height: 24px;
}

input:not([type='checkbox']):not([type='radio']) {
    box-sizing: border-box;
    border: 2px solid $black;
    padding: 0.5rem 0.7rem;
    font-weight: bold;
    letter-spacing: 0.1px;
}

// To make disabled input Label text opaque, use label[for="*InputId*"]
input:disabled,
input.disabled,
div.input-checkbox.disabled,
div.input-radio.disabled {
    opacity: 0.25;
}

input:not([type='checkbox']):not([type='radio']):focus {
    border: 2px solid var(--primary);
    box-shadow: inset 0 -1px 0 transparent;
}
