﻿@import '../../../../../Common/src/scss/components/react/ProductTabContent.scss';


.ProductTabContent {

    .nav-link {

        &.active {
            border-bottom: 3px solid $black
        }
    }
}
