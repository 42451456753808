﻿@import '../../../../../Common/src/scss/components/react/ProductGallery.scss';

.ProductGallery {

    button img {
        padding: 5px;
        border: 2px solid $black;
    }

    button.selected img {
        border: 2px solid $primary
    }

    @include media-breakpoint-down(sm) {

        button.selected img, button img {
            border: 2px solid transparent;
        }

        .slick-dots {

            li button:before {
                background: none;
                border: 1px solid $black;
            }

            li.slick-active button:before {
                background: $black;
            }
        }
    }
}
