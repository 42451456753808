﻿@import '../../../Common/src/scss/components/_forms.scss';

.ListFormElementBlock {
    .FormTextbox {
        width: 60% !important;

        @include media-breakpoint-down(md) {
            width: 100% !important;
        }

        &__Label {
            width: 34%;

            @include media-breakpoint-down(md) {
                width: 100% !important;
            }
        }

        &__Input {
            flex-grow: 1;
            width: 65% !important;

            @include media-breakpoint-down(md) {
                width: 100% !important;
            }
        }
    }

    &__Columns {
        width: 60%;

        select {
            width: 100%;
        }
    }

    &__Column--ButtonWrapper {
        width: 5%;
        white-space: nowrap;
    }

    &__AddRemoveButton {
        border: none;
        background-color: transparent;
        color: var(--primary);
    }
}

.dynamicaddressformelementblock {
    .ListFormElementBlock__Column {
        select {
            display: unset;
        }
    }
}

.Form__Element {
    label {
        min-width: 33% !important;
    }

    .FormChoice__Label {
        display: inline !important;
        letter-spacing: 0.10px;
    }

    .FormSelection {
        select {
            width: 60% !important;

            @include media-breakpoint-down(md) {
                width: 100% !important;
            }
        }
    }

    .FormTextbox {
        width: 60% !important;

        @include media-breakpoint-down(md) {
            width: 100% !important;
        }

        &__Caption {
            min-width: 30% !important;
            border: 1px solid green;
        }

        &__Label {
            width: 34%;

            @include media-breakpoint-down(md) {
                width: 100% !important;
            }
        }

        &__Input {
            width: 65% !important;

            @include media-breakpoint-down(md) {
                width: 100% !important;
            }
        }
    }
}

.Form__Element__DynamicAddress {
    .Form__Element {
        width: 60% !important;

        @include media-breakpoint-down(md) {
            width: 100% !important;
        }

        &__Caption {
            width: 33%;

            @include media-breakpoint-down(md) {
                width: 100% !important;
            }
        }

        select {
            display: inline-block !important;
            width: 65% !important;

            @include media-breakpoint-down(md) {
                width: 100% !important;
            }
        }
    }
}

.Form__CustomElement {
    &__Table {
        width: 60%;

        @include media-breakpoint-down(md) {
            width: 100%;
        }
    }

    &__List {
        .Form__Element__List__Item {
            width: 60% !important;

            @include media-breakpoint-down(md) {
                width: 100%;
            }
        }
    }
}

.selectionelementblock {
    .ListFormElementBlock__Column {
        width: 60% !important;

        @include media-breakpoint-down(md) {
            width: 100% !important;
        }

        select {
            display: block;
            width: 100%;
        }
    }
}
