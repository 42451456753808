﻿.footer__section {
    font-size: 14px;
    font-family: var(--primary-font);
    padding-top: 30px;
    background-color: $black;
    color: $white !important;

    & .subheader {
        color: var(--primary);
        text-transform: uppercase;
        padding-bottom: 10px;
        line-height: 0.9em;
        font-size: $font-size-base;
    }

    .copyright {
        p,
        a {
            color: $white;
        }
    }

    @include media-breakpoint-down('md') {
        padding-left: 15% !important;
        padding-right: 15% !important;
        padding-top: 60px;
    }

    @include media-breakpoint-down('xs') {
        padding-left: 5% !important;
        padding-right: 5% !important;
        padding-top: 60px;
    }
}

.footer__navgroup {
    & .navgroup__links {
        list-style: none;
        padding-left: 0;
        padding-bottom: 5px;
        @include font-size(14);
        padding-top: 20px;
    }

    & .navgroup__link a {
        font-weight: normal !important;
        line-height: 36px !important;
    }

    .btn {
        padding: 0px;
    }

    .accordion {
        margin-bottom: 1.5em;
    }

    .fa-chevron-down {
        color: var(--primary);
        font-size: 12px;
    }
}

.footer__email {
    font-size: 16px;
    color: var(--primary);
}

.footer__section a {
    color: $white;
    font-size: $font-size-md;
}

@include media-breakpoint-down(md) {
    .footer__logo img {
        height: 100px;
    }
}
