.formcontainerblock {
    .territory-approved {
        background-color: $approved;
        color: $white;
    }

    .territory-declined {
        background-color: $declined;
        color: $white;
    }
}
