﻿@import '../../../../../Common/src/scss/components/react/Table.scss';

.Table {
    thead {
        tr {
            padding-bottom: 12px;
        }

        border-bottom: 2px solid var(--primary);

        th span {
            @extend .display-4;
            font-family: var(--secondary-font);
            letter-spacing: 1.5px;
        }
    }

    tbody {
        tr {
            border-bottom: 1px solid $black;
        }

        .btn {
            background: var(--primary);
        }
    }
}
