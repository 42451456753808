﻿@import '../../../../../Common/src/scss/components/react/ProductInput.scss';

.ProductInput {
    input {
        width: 100%;
        height: 2.625em;
    }

    button {
        height: 2.625rem;
    }

    button.buy-now {
        background: linear-gradient($primary-layer-1, $primary-layer-1), linear-gradient(var(--primary), $primary);
    }
}
