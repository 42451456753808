.choiceelementblock {
    fieldset {
        label {
            align-items: center !important;
        }

        input[type='checkbox'] {
            width: 10px;
            min-width: 10px;
            height: 10px;
            min-height: 10px;
            padding: 10px 10px;
        }
    }
}
