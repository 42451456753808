﻿.hero__block {
    background-color: transparent;
    margin-bottom: 0px;

    .display-1 {
        margin-bottom: 0px;
        text-transform: none !important;
    }

    .subheader {
        margin-bottom: 11px;
    }

    .btn-lg {
        margin-top: 50px;
    }

    @include media-breakpoint-down(sm) {

        .btn-lg {
            margin-top: 31px
        }
    }
}
