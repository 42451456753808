﻿@import '../../../../../Common/src/scss/components/react/CatalogCard.scss';

.CatalogCard {
    h1 {
        @include font-size(19px);
        font-family: var(--secondary-font);
        font-weight: 400;
        letter-spacing: 1px;
        color: $semi-dark;
    }

    &__flag {
        > span {
            font-weight: 900;
        }
    }

    &__prices {
        justify-content: center;
    }

    &__links {
        justify-content: center;
    }
}
