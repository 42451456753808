﻿@import '../../../../../Common/src/scss/components/react/CatalogGrid.scss';

.CatalogGrid {
    .row > .border-col {
        border-bottom: 1px solid #E1E1E1;
        border-right: 1px solid #E1E1E1;
    }
}

@media (min-width: 768px) {
    .CatalogGrid {
        > .row {
            > .border-col:nth-of-type(3n),
            > .border-col:nth-of-type(3n-1):nth-last-of-type(1),
            > .border-col:nth-of-type(3n-2):nth-last-of-type(1) {
                border-right: none;
            }

            > .border-col:nth-last-of-type(1):nth-of-type(3n),
            > .border-col:nth-last-of-type(1):nth-of-type(3n-1),
            > .border-col:nth-last-of-type(1):nth-of-type(3n-2),
            > .border-col:nth-last-of-type(2):nth-of-type(3n-1),
            > .border-col:nth-last-of-type(2):nth-of-type(3n-2),
            > .border-col:nth-last-of-type(3):nth-of-type(3n-2) {
                border-bottom: none;
            }
        }
    }
}

@media (max-width: 767px) {
    .CatalogGrid {
        > .row {
            > .border-col:nth-of-type(2n),
            > .border-col:nth-of-type(2n-1):nth-last-of-type(1) {
                border-right: none;
            }

            > .border-col:nth-last-of-type(1):nth-of-type(2n),
            > .border-col:nth-last-of-type(1):nth-of-type(2n-1),
            > .border-col:nth-last-of-type(2):nth-of-type(2n-1) {
                border-bottom: none;
            }

            > .CatalogCTA ~ .border-col:nth-of-type(2n) {
                border-right: 1px solid #E1E1E1;
            }

            > .CatalogCTA ~ .border-col:nth-of-type(2n+1),
            > .CatalogCTA ~ .border-col:nth-of-type(2n):nth-last-of-type(1) {
                border-right: none;
            }

            > .CatalogCTA ~ .border-col:nth-last-of-type(1):nth-of-type(2n+1),
            > .CatalogCTA ~ .border-col:nth-last-of-type(1):nth-of-type(2n),
            > .CatalogCTA ~ .border-col:nth-last-of-type(2):nth-of-type(2n) {
                border-bottom: none;
            }
        }
    }
}
