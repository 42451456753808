﻿@import '../../../../../Common/src/scss/components/react/CatalogFilters.scss';

.CatalogFilters {
    background: #F3F6F6;

    > section {

        > div:not(:last-of-type) {
            border-right: 2px solid $black;
        }
    }
}
