﻿.header__banner {
    text-align: center !important;
    @include font-size(14);
    background: $white;
    text-align: center;
}

.header__mast {
    padding: 16px 0px;
    color: $black;
    background-color: $white;
    font-size: 19px;

    i.fa.fa-bars {
        font-size: 30px;
        color: $black;
    }

    @include media-breakpoint-down(lg) {
        .header__mast__logo {
            height: 70px;
        }
    }
}
