﻿.accordion.light {
    background: $light;
    border-right: 2px solid $black;
    border-left: 2px solid $black;
    border-top: 2px solid $black;

    & .card-header {
        border-bottom: 2px solid $black;
        padding: 0px;

        & button {
            padding-left: 20px;
            padding-right: 20px;
            align-items: center;
            margin-top: 2px; //this is to properly center items due to border
        }
    }

    & .collapse > p,
    .collapsing > p {
        padding: 10px 20px;
    }
}

.accordion.dark {
    background: $dark;

    & .card-header {
        padding: 0px;

        & button {
            padding: 20px;
            align-items: center;
        }

        & .subheader,
        i {
            color: var(--primary);
        }
    }

    & .collapse > p,
    .collapsing > p {
        padding-left: 20px;
        padding-right: 20px;
        color: $light;
    }
}
