﻿.CatalogFilters {
    width: 100%;
    height: 57px;
    display: flex;
    align-items: stretch;
    justify-content: center;
    position: relative;
    overflow: visible;

    > section {
        display: flex;
        flex-direction: row;
        align-items: stretch;
        justify-content: space-between;
        position: relative;

        > div {
            margin: 0.5em 0;
            display: flex;
            align-items: center;
            justify-content: flex-start;
        }
    }

    &__title {
        @include font-size(16px);
        font-weight: bold;
        font-family: var(--tertiary-font);
        margin-right: 0.5em;
        margin-left: 0.5em;
    }

    &__info {
        padding-right: 0.5em;
    }

    &__filters {
        flex: 1 0 0%;
        flex-direction: row;
        padding-left: 0.5em;

        > *:not(.CatalogFilters__title) {
            margin: 0 -0.5em;
        }
    }

    &__paging {
        display: flex;
        flex-direction: row;
        align-items: center;

        > * {
            margin: 0 -0.5em;
        }

        .FilterDropdown > .btn {
            padding: 0.5em;
        }
    }
}
